<template>
  <div class="container">
    <div class="type-select">
      <div class="item"
        :class="{ active: curType === '' }"
        @click="handleSelect()">
        全部
      </div>
      <div class="item"
        :class="{ active: curType === 1 }"
        @click="handleSelect(1)">
        保险科技
      </div>
      <div class="item"
        :class="{ active: curType === 2 }"
        @click="handleSelect(2)">
        传统保险
      </div>
    </div>
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      tableName="rickProjectList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum"
      @search-change="getList"
      @export-data="exportData"
      @add-change="editDetail({})"
      @import-click="isImport = true"
    >
      <template slot="enable" slot-scope="{ item }">
        <el-tag type="success" v-if="item.enable">启用</el-tag>
        <el-tag type="danger" v-else>禁用</el-tag>
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          @click.native.prevent="goDetail(item)"
          type="text"
          size="small"
          >查看详情</el-button
        >
        <el-button
          class="polecy-button" v-if="hasPerms('project-edit')"
          @click.native.prevent="editDetail(item)"
          type="text"
          size="small"
          >编辑</el-button
        >
        <el-button v-if="hasPerms('project-edit')"
          class="polecy-button" style="color:rgba(246, 74, 45, 1)"
          @click.native.prevent="handleDel(item)"
          type="text"
          size="small"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <partsRetrievalImport v-model="isImport" @changeList="changeGetList" />
    <partsRetrievalDialog
      v-model="isAddVisible"
      :editId="editData.id"
      @changeList="changeGetList"
    />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import partsRetrievalImport from "@/views/PartsRetrieval/components/partsRetrievalImport.vue";
import partsRetrievalDialog from "@/views/PartsRetrieval/components/partsRetrievalDialog.vue";
import {
  newRiskProject,
  delRickProject,
  rickProjectExportFile
} from "@/api/basicData";
import {
  pageProjectList,
  removeBatchById,
  listApplyDownload,
} from "@/api/project";
import { hasPerms } from "@/utils/auth";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import dayjs from "dayjs";
import { dictionaryBatch } from "@/api/policy";
export default {
  name: "ProjectManagementList",
  components: { GeneralTable, partsRetrievalImport, partsRetrievalDialog },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      option: {
        isAdd: hasPerms('project-add'),
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isExport: true, // 导入 @import-click
        isSelection: false,
        disFuzzySearch: true,
        pageSize: 10,
        searchList: [
          {
            label: "项目名称",
            prop: "projectName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          // {
          //   label: "项目类型",
          //   prop: "projectCategoryValue",
          //   formType: "select",
          //   filterable: true,
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   selectList: [
          //     {
          //       text: 'Project',
          //       value: 1
          //     }
          //   ],
          // },
          {
            label: "项目性质",
            prop: "projectTypeValue",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "产品线",
            prop: "productTypeValue",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
          },
          {
            label: "启用状态",
            prop: "enable",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: false, text: "禁用" },
              { value: true, text: "启用" },
            ],
            clearable: true,
            placeholder: "请选择",
          },
          {
            label: "添加时间",
            prop: "createDateTime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["createTimeBegin", "createTimeEnd"],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "项目名称",
          prop: "projectName",
          isShow: true,
        },
        {
          label: "项目类型",
          prop: "projectCategory",
          isShow: true,
        },
        {
          label: "项目性质",
          prop: "projectType",
          isShow: true,
        },
        {
          label: "产品线",
          prop: "productType",
          isShow: true,
        },
        {
          label: "启用状态",
          prop: "enable",
          isShow: true,
          isSlot: true,
        },
        // {
        //   label: "有效时间",
        //   prop: "expirationDate",
        //   isShow: true,
        // },
        {
          label: "项目负责人",
          prop: "projectLeader",
          isShow: true,
        },
        {
          label: "排序",
          prop: "order",
          isShow: true,
        },
        {
          label: "创建人",
          prop: "createUserName",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 180,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: {},
      oldSearchData: {},
      operateTitle: "添加",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {
        deviceType: "",
        deviceCategory: "",
        topLevelFlag: true,
      },
      curType: '',
      hasPerms,
    };
  },
  created() {
    dictionaryBatch({ codes: "riskCtrlType,projectlines" }).then((res) => {
      this.option.searchList.find((item) => {
        if (item.prop === "projectTypeValue") {
          item.selectList = res.data.riskCtrlType.map((code) => {
            return {
              text: code.dictValue,
              value: code.dictKey,
            };
          });
        }
        if (item.prop === "productTypeValue") {
          item.selectList = res.data.projectlines.map((code) => {
            return {
              text: code.dictValue,
              value: code.dictKey,
            };
          });
        }
      });
      //console.log(res);
    });
  },
  activated() {
    console.log('--------------------------------');
  },
  computed: {},
  methods: {
    getList(v, pageNum, pageSize, n, type) {
      if (
        v.createTimeBegin &&
        v.createTimeBegin.split(" ").length === 1
      ) {
        v.createTimeBegin =
          dayjs(v.createTimeBegin).format("YYYY-MM-DD") + " 00:00:00";
        v.createTimeEnd =
          dayjs(v.createTimeEnd).format("YYYY-MM-DD") + " 23:59:59";
      }
      // , deviceType: this.deviceTypeList.toString()
      // , partBrand: this.partBrandList.toString()
      this.oldSearchData = Object.assign(
        { ...this.ruleForm },
        {
          ...v,
          index: pageNum,
          size: pageSize,
          fuzzyLookupField: v.keyword,
          bladeUserId: this.$store.state.userInfo.MJUserId,
          projectCategoryValue: this.curType,
          projectLevel: -1
        }
      );
      newRiskProject(this.oldSearchData).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      }).catch((err) => {
        this.dataList = [];
        this.totalNum = 0;
      });;
    },
    handleClassification(e, type) {
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? (this[type] = []) : (this[type] = [e]);
      this.changeGetList();
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      console.log("详情");
      this.$router.push({
        path: "/routerProjectManagement/projectDetails?id=" + e.id,
      });
    },
    exportData(e) {
      rickProjectExportFile({
        ...this.oldSearchData,
        ids: e.map((val) => val.id).toString(),
      });
    },
    handleDel(e) {
      this.$confirm("确认删除项目?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRickProject([e.id]).then((res) => {
          this.changeGetList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editDetail(e) {
      this.$router.push({
        path: `/routerProjectManagement/addProject${e.id ? "?id=" + e.id : ""}`,
      });
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({
        dictType: this.machineryProductTypeList.find(
          (val) => val.dictName == this.ruleForm.deviceType
        ).dictType,
      })
        .then((res) => {
          if (res.flag === 1) {
            this.machineryEquipmentTypeList = res.obj;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.ruleForm.deviceCategory = "";
      this.getMachineryEquipmentTypeList();
    },
    handleSelect(e) {
      this.curType = e || '';
      this.changeGetList();
    }
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
  }
}
.enableStatus1 {
}
.enableStatus2 {
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

  .type-select {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: -10px;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    .item {
      position: relative;
      padding: 13px 20px;
      color: #606266;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #409eff;
      }
    }
    .active {
      color: #4278c9;
      font-weight: bold;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        border-radius: 4px;
        background-color: #4278c9;
      }
    }
  }
</style>
